import { faker } from '@faker-js/faker';

import { genFullName } from '@/helpers';
import {
  CompanyKybStatus,
  CompanyProviderIncomePaymentMethod,
  CompanyProviderOnboardingStatus,
  CompanyProviderOnboardingType,
  CompanyProviderOutcomePaymentMethod,
  CompanyProviderPaymentService,
  CompanyProviderStatus,
  CompanyUserRole,
  CompanyUserStatus,
  CoreUserPermissionKey,
  CoreUserStatus,
} from '@/enums';
import type {
  Company,
  CompanyUser,
  CoreUser,
  CoreUserMe,
  CoreUserMePermissions,
} from '@/interfaces';

/**
 * TODO Пока так. вернуть invite когда починим бд
 */

const permissions = Object.values(CoreUserPermissionKey).reduce(
  (accum, currentValue) => {
    return {
      ...accum,
      [currentValue]: { read: true, create: true },
      companyUser: { read: true, invite: false },
      transaction: { read: true, internal: true },
    };
  },
  {},
) as CoreUserMePermissions;

export const AllSeedsData = {
  companyNames: ['AdsDrive Agency', 'GrowConnect', 'AdFinity', 'Boosta Agency'],
  primaryCompanyNames: ['General Expenses', 'Main Account USD'],
  subAccountsNames: ['Savings', 'Investments', 'Tax', 'Salary', 'Insurance'],
  budgets: [
    {
      name: 'Content marketing',
      counterparties: [
        'Copy.io',
        'Medium',
        'Grammarly',
        'Fiverr',
        'Dropbox',
        'Jasper',
        'ConvertFlow',
        'Canva',
        'Ahrefs Content Explorer',
        'GatherContent',
        'Shutterstock',
        'Wordtune',
        'monday.com',
        'Buffer',
        'ContentStudio',
        'Loomly',
        'SnapApp',
        'Leadpages',
        'StoryChief',
        'ContentCal',
      ],
    },
    {
      name: 'Advertising',
      counterparties: [
        'Google Ads',
        'Facebook Ads',
        'TikRok Ads',
        'Reddit',
        'Google Analytics',
        'Instagram',
        'LinkedIn',
        'Amazon',
        'Yahoo',
        'Twitter Ads',
        'AdRoll',
        'Snapchat Ads',
        'AdMob',
        'Outbrain',
        'YouTube Video Advertising',
        'Apple Search Ads',
        'Taboola',
        'BuySellAds',
        'Infolinks',
        'Revcontent',
      ],
    },
    {
      name: 'Mail & SMS marketing',
      counterparties: [
        'Mailchimp',
        'Klaviyo',
        'GetResponse',
        'Campaign Monitor',
        'ConvertKit',
        'HubSpot',
        'iContact',
        'AWeber',
        'Sendinblue',
        'EZ Texting',
        'SlickText',
        'MobileMonkey',
        'Postscript',
        'WebEngage',
        'Omnisend',
        'Heymarket',
        'Keap',
        'Mailify',
        'Constant Contact',
        'Zoho Campaigns',
      ],
    },
    {
      name: 'Sales',
      counterparties: [
        'HubSpot',
        'Intercom',
        'Zendesk',
        'Leadfeeder',
        'Asana',
        'Basecamp',
        'Contacts+',
        'Snov.io',
        'Cavalry',
        'Salesmate',
        'Zoom',
        'Calendly',
        'Hunter.Io',
        'Acquire',
        'Typeform',
        'ReferralCandy',
        'Outreach',
        'Zoho CRM',
        'Pandadoc',
        'Milkshake',
      ],
    },
    {
      name: 'Development',
      counterparties: [
        'GitHub',
        'AppCode',
        'Appcelerator',
        'Quick Base',
        'JIRA',
        'Azure',
        'Atom',
        'SendBird',
        'Linx',
        'Cloud 9',
        'Codenvy',
        'Crimson',
        'Bootstrap',
        'UltraEdit',
        'Vim',
        'Docker',
        'Axure',
        'Appsheet',
        'Buddy',
        'Kobiton',
      ],
    },
    {
      name: 'Web & Graphic Design',
      counterparties: [
        'Figma',
        'Miro',
        'Adobe Creative Cloud',
        'Sketch',
        'InVision',
        'Webflow',
        'Zeplin',
        'Mockplus',
        'Axure RP',
        'Khroma',
        'Creative Marker',
        'Paratype',
        'LucidChart',
        'Framer',
        'Marvel',
        'Balsamiq',
        'iStock',
        'Yellow Images',
        'Mockup Maison',
        'Smart Objects',
      ],
    },
    {
      name: 'Subscriptions',
      counterparties: [
        'ClickUp',
        'Dropbox',
        'Trello',
        'Figma',
        'Adobe Creative Cloud',
        'AWS',
        'Google Cloud',
        'Azure',
        'Jira',
        'Zoom',
        'Slack',
        'HubSpot',
        'Webflow',
        'Convertflow',
        'Mailchimp',
        'Voluum',
        'ClickMeter',
        'Shopify',
        'Grammarly',
        'Medium',
      ],
    },
    {
      name: 'Finance',
      counterparties: [
        'DocuSign',
        'Xero',
        'Quickbooks',
        'Gusto',
        'Freshbooks',
        'Kefron AP',
        'FinancialForce',
        'Unit4',
        'Stripe',
        'Chargebee',
        'Payfit',
        'Zoho Books',
        'PayPal',
        'Paychex',
        'PlanGuru',
        'Procurify',
        'Tableau',
        'Metabase',
        'Cflow',
        'Future Advisor',
      ],
    },
    {
      name: 'HR',
      counterparties: [
        'Workday',
        'Breezy HR',
        'Zoho People',
        'ADP',
        'Caroo',
        'Spark Hire',
        'Assembly',
        'Otter',
        'HeadHunter',
        'Workbright',
        'Recruitee',
        'SurveyMonkey',
        'JobScore',
        'Textio',
        'HireEZ',
        'Lever',
        'Agorapulse',
        'Hootsuite',
        'Eventbrite',
        'Meetup',
      ],
    },
    {
      name: 'Analytics',
      counterparties: [
        'Supermetrics',
        'Funnel',
        'Digivizer',
        'GoodData',
        'Tableau',
        'SAS',
        'Looker',
        'Zoho Analytics',
        'Domo',
        'Microsoft Power BI',
        'Klipfolio',
        'Sisense',
        'Systum',
        'Google Analytics',
        'Altair',
        'Amazon Web Services',
        'Board',
        'Oracle',
        'Clicdata',
        'Periscope',
      ],
    },
    {
      name: 'Market Research',
      counterparties: [
        'Pollfish',
        'Typeform',
        'Hubspot',
        'Survey Monkey',
        'Answer the Public',
        'Similarweb Shopper Intelligence',
        'Latana',
        'Hootsuite',
        'Tableau',
        'Temper',
        'Remesh',
        'BIT.AI',
        'Attest',
        'Mention',
        'Heartbeat AI',
        'Stravito',
        'Qualtrics',
        'Statista',
        'Ahrefs',
        'Nfield',
      ],
    },
    {
      name: 'Branding',
      counterparties: [
        'Adobe Creative Cloud',
        'Wistia',
        'Frontify',
        'Quordoba',
        'Bynder',
        'Flipsnack',
        'Figma',
        'Miro',
        'Yotpo',
        'Amplifi.io',
        'Canva',
        'Frontify',
        'Brandfolder',
        'Brand24',
        'Brandwatch',
        'Cision',
        'Sprout Social',
        'Uberall',
        'Zendesk',
        'ClickUp',
      ],
    },
    {
      name: 'PR & Media',
      counterparties: [
        'LinkedIn Premium',
        '500NewsWire',
        'Bloomberg',
        'Medium',
        'WordPress',
        'Cision',
        'PR Web',
        'BuzzGuru Influencer Analytics',
        'Muck Rack',
        'Hootsuite',
        'CoverageBook',
        'Brandwatch',
        'Twitter Blue',
        'Reddit',
        'Vappingo',
        'Fiverr',
        'Croud',
        'Forbes',
        'BenchmarkOne',
        'Affable',
      ],
    },
    {
      name: 'Events & Sponsorships',
      counterparties: [
        'Boot Camp Workshop',
        'Amplify Summit',
        'Bizzabo',
        'RampUp 2023',
        'GURU Conference',
        'Marketing 2.0 Conference',
        'Rise.co',
        'GROW Los Angeles',
        'SXSW 2023',
        'LEADSCON 2023',
        'Ceres Global',
        'Kindred Society',
        'Engage Martech',
        'Syspree',
        'eTail Connect',
        'APAC Summit',
        'DigiMarCon America 2023',
        'Yycthree',
        'CRO Summit San Francisco 2023',
        'MarTech',
      ],
    },
    {
      name: 'SEO',
      counterparties: [
        'Ahrefs',
        'KWFinder',
        'WooRank',
        'SEMrush',
        'BrightLocal',
        'SE Ranking',
        'Mangools',
        'SimilarWeb',
        'RankMath',
        'Moz Pro',
        'Google Analytics',
        'Yoast',
        'Rank Ranger',
        'Ubersuggest',
        'Surfer',
        'Frase.io',
        'AccuRanker',
        'Serpstat',
        'WriterZen',
        'Seodity',
      ],
    },
    {
      name: 'Consulting',
      counterparties: [
        'Slack',
        'Asana',
        'DrumUp',
        'Filestage',
        'Milanote',
        'Keap',
        'Trello',
        'Harvest',
        'Zoom',
        'Calendly',
        'Mailchimp',
        'Twilio',
        'Canva',
        'Hootsuite',
        'AskBrian',
        'Agolo',
        'Think-cell',
        'PitchBook',
        'Toggl',
        'OmniFocus',
      ],
    },
    {
      name: 'Legal',
      counterparties: [
        'Box',
        'iManage',
        'SimpleLegal',
        'Clio',
        'Legal Tracker',
        'Brightflag',
        'Juro',
        'Agiloft',
        'Concord',
        'Conga Contracts',
        'Contract Express',
        'ContractPodAI',
        'Tipalti',
        'Contractbook',
        'PandaDoc',
        'Jotform',
        'TermScout',
        'Oneflow',
        'Formstack Documents',
        'Deel',
      ],
    },
    {
      name: 'SaaS',
      counterparties: [
        'Braze',
        'Slite',
        'Zapier',
        'Loom',
        'HotJar',
        'Clearbit',
        'Sprout Social',
        'The Tray Platform',
        'Unbounce',
        'Hubspot',
        'SEMrush',
        'Aeroleads',
        'Tableau',
        'Albacross',
        'Encharge',
        'Userpilot',
        'Mixpanel',
        'Heap',
        'ActiveCampaign',
        'Drip',
      ],
    },
    {
      name: 'Customer Support',
      counterparties: [
        'Hubspot',
        'Intercom',
        'WhatsApp Business',
        'Manychat',
        'Help Scout',
        'Zendesk',
        'Loom',
        'Wistia',
        'Slack',
        'Slab',
        'Zoom',
        'Pitch',
        'Jira',
        'Recurly',
        'LiveAgent',
        'Hootsuite',
        'SurveyMonkey',
        'Recurly',
        'Freshdesk',
        'Hiver',
      ],
    },
    {
      name: 'Web Hosting',
      counterparties: [
        'cPanel',
        'BoldGrid',
        'OpenCard',
        'WordPress',
        'Bluehost',
        'A2 Hosting',
        'SiteGround',
        'HostGator',
        'Cloudways',
        'AccuWeb',
        'WP Engine',
        'SiteChecker',
        'Rishavapps',
        'Geekflare',
        'Linode',
        'GoDaddy',
        'Turbify',
        'AWS',
        'WebFX',
        'Digital Ocean',
      ],
    },
  ],
};

export const genCoreUserMe = (
  companies: Company[],
  companyUser: CompanyUser,
): CoreUserMe => {
  return {
    id: companyUser.user.id,
    firstName: companyUser.user.firstName,
    lastName: companyUser.user.lastName || '',
    fullName: companyUser.user.fullName,
    avatar: companyUser.user.avatar || '',
    status: companyUser.user.status,
    phone: faker.phone.number(),
    email: 'demo@gmail.com',
    emailVerified: true,
    permissions,
    phoneVerified: false,
    companyUsers: [
      {
        id: companyUser.id,
        company: {
          ...companies[0],
          kybStatus: CompanyKybStatus.Approved,
          getStartedProcessState: undefined,
          hasExternalAccounts: false,
          hasCompanyAccounts: true,
          plan: {
            activeCardsLimit: 1000,
            stripeCardIssuingFee: '$0.10',
            monthlyCardIssuingLimit: 50,
          },
          createdCardsThisMonth: faker.number.int({ min: 0, max: 50 }),
          providers: [
            {
              id: 1,
              providerId: 1,
              paymentService: CompanyProviderPaymentService.Stripe,
              status: CompanyProviderStatus.Active,
              kybStatus: CompanyKybStatus.Approved,
              isCbEnabled: false,
              isCompanyAccountFunded: true,
              canSendFunds: false,
              onboardingStatus: CompanyProviderOnboardingStatus.Approved,
              onboardingType: CompanyProviderOnboardingType.Full,
              incomePaymentMethods: [
                CompanyProviderIncomePaymentMethod.InboundAch,
                CompanyProviderIncomePaymentMethod.InboundWire,
              ],
              outcomePaymentMethods: [
                CompanyProviderOutcomePaymentMethod.OutboundAch,
                CompanyProviderOutcomePaymentMethod.OutboundWire,
              ],
            },
          ],
        },
        uiSettings: undefined,
        timezone: 'UTM',
        role: CompanyUserRole.Owner,
        status: CompanyUserStatus.Active,
      },
    ],
    companyUserId: 1,
    createdAt: '',
  };
};

export const genCoreUsers = (count: number): CoreUser[] => {
  const users: CoreUser[] = [];

  for (let i = 1; i <= count; i += 1) {
    const firstName = faker.person.firstName();
    const lastName = faker.person.lastName();
    const username = faker.lorem.word();
    const avatar = faker.image.avatar();

    users.push({
      id: i,
      firstName,
      lastName,
      fullName: genFullName(firstName, lastName),
      username,
      auth0Id: `auth0_id_${i}`,
      avatar,
      status: CoreUserStatus.Active,
      permissions: {
        create: true,
        read: true,
        update: true,
        destroy: true,
        me: true,
        kybLink: false,
      },
    });
  }

  return users;
};
