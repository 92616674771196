import { faker } from '@faker-js/faker';

import { getRandomItemFromArray } from '@/mocks';
import { AllSeedsData } from '@/seeds';
import {
  CounterpartyBusinessType,
  CounterpartyPaymentAccountTransferType,
  CounterpartyPaymentMethod,
  Currency,
} from '@/enums';

import type { Counterparty, CounterpartyPaymentAccount } from '@/interfaces';

export const genInvoicePrefix = () => {
  const symbol = () => faker.helpers.arrayElement(['A', 'B', 'C', 'D']);

  return `${symbol()}${symbol()}${symbol()}`;
};

export function genCounterparties(): Counterparty[] {
  const counterparties: Counterparty[] = [];
  const counterpartiesNames: Counterparty['name'][] = [];

  AllSeedsData.budgets.forEach(item => {
    item.counterparties.forEach(name => {
      if (counterpartiesNames.includes(name)) return;
      counterpartiesNames.push(name);
    });
  });

  counterpartiesNames.forEach((name, i) => {
    const income = faker.commerce.price({ min: 100, max: 20000 });
    const spend = faker.commerce.price({ min: 100, max: 20000 });
    const transactionsSum = (Number(income) - Number(spend)).toFixed(2);
    counterparties.push({
      id: i + 1,
      name,
      logo: undefined,
      businessType: getRandomItemFromArray(
        Object.values(CounterpartyBusinessType),
      ) as CounterpartyBusinessType,
      country: 'US',
      email: faker.internet.email(),
      paymentMethods: [CounterpartyPaymentMethod.Wire],
      invoicePrefix: genInvoicePrefix(),
      spend,
      income,
      transactionsSum,
      currency: Currency.Usd,
      lastTransactionDate: new Date(),
      permissions: {
        read: true,
        update: true,
        destroy: false,
      },
    });
  });

  return counterparties;
}

export function genCounterpartiesPaymentAccounts(
  counterparties: Counterparty[],
): CounterpartyPaymentAccount[] {
  const paymentAccounts: CounterpartyPaymentAccount[] = [];

  counterparties.forEach((counterparty, i) => {
    paymentAccounts.push({
      id: i + 1,
      counterpartyId: counterparty.id!,
      name: faker.company.name(),
      beneficiaryName: faker.finance.accountName(),
      bank: {
        name: faker.company.name(),
        logo: faker.image.avatar(),
      },
      transferType: CounterpartyPaymentAccountTransferType.Ach,
      permissions: { read: true, update: false, destroy: false },
      accountNumber: faker.finance.accountNumber(),
      routingNumber: faker.finance.routingNumber(),
      country: 'US',
      isDraft: false,
      addressLine1: faker.location.streetAddress(),
      city: faker.location.city(),
      state: faker.location.state(),
      zipCode: faker.location.zipCode(),
    });
  });

  return paymentAccounts;
}
