import { HttpResponse, delay, http } from 'msw';
import { faker } from '@faker-js/faker';

import { convertStringToArray, db } from '@/mocks';
import { useEnv } from '@/composables';
import { genInvoicePrefix } from '@/seeds';

import { CounterpartyPaymentMethod, Currency } from '@/enums';
import type {
  Counterparty,
  CounterpartyCreateRequest,
  CounterpartyPaymentAccount,
  CounterpartyPaymentAccountCreateRequest,
} from '@/interfaces';

const { apiBaseUrl } = useEnv();

const baseUrl = `${apiBaseUrl}/api/v1/app`;

const matchCounterpartiesWithPaymentAccounts = (
  counterparties: Partial<Counterparty>[],
) => {
  return counterparties.map(async counterparty => {
    const paymentAccounts = await db.counterpartyPaymentAccounts
      .where('counterpartyId')
      .equals(Number(counterparty.id))
      .toArray();

    return {
      ...counterparty,
      paymentAccounts: paymentAccounts.map(paymentAccount => ({
        id: paymentAccount.id,
        name: paymentAccount.name,
      })),
    };
  });
};

export const readCounterparties = http.get(
  `${baseUrl}/core/counterparty/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const includedIds = convertStringToArray('included_ids', url);
    const businessType = convertStringToArray('business_types', url, false);
    const countries = convertStringToArray('countries', url, false);
    const extraFields = convertStringToArray('extra_fields', url, false);

    const filterByBusinessType = (counterparty: Partial<Counterparty>) => {
      if (!businessType.length || !counterparty.businessType) return true;
      return Boolean(businessType.includes(counterparty.businessType));
    };

    const filterByCountries = (counterparty: Partial<Counterparty>) => {
      if (!countries.length || !counterparty.country) return true;
      return Boolean(countries.includes(counterparty.country));
    };

    const filterByIncludedIds = (counterparty: Partial<Counterparty>) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds
        .map(id => Number(id))
        .includes(Number(counterparty.id));
    };

    const filterByIncludedIdsExcept = (counterparty: Partial<Counterparty>) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds
        .map(id => Number(id))
        .includes(Number(counterparty.id));
    };

    const count = await db.counterparties
      .where('name')
      .startsWithIgnoreCase(search)
      .filter(filterByBusinessType)
      .filter(filterByCountries)
      .count();

    const includedCounterparties = includedIds.length
      ? await db.counterparties.filter(filterByIncludedIds).toArray()
      : [];

    const otherCounterparties = await db.counterparties
      .where('name')
      .startsWithIgnoreCase(search)
      .filter(filterByIncludedIdsExcept)
      .filter(filterByBusinessType)
      .filter(filterByCountries)
      .offset(offset)
      .limit(limit)
      .reverse()
      .sortBy('id');

    let results = [...includedCounterparties, ...otherCounterparties];

    if (extraFields.includes('payment_accounts')) {
      results = await Promise.all(
        matchCounterpartiesWithPaymentAccounts(results),
      );
    }

    await delay(300);
    return HttpResponse.json({
      results,
      count,
    });
  },
);

export const readCounterpartyById = http.get(
  `${baseUrl}/core/counterparty/:id/`,
  async ({ params }) => {
    const counterpartyById = await db.counterparties
      .where('id')
      .equals(Number(params.id))
      .first();

    await delay(500);
    return HttpResponse.json(counterpartyById);
  },
);

export const createCounterparty = http.post(
  `${baseUrl}/core/counterparty/`,
  async ({ request }) => {
    const body = (await request.json()) as object;
    const { name, email, country, businessType }: CounterpartyCreateRequest =
      camelizeKeys(body);

    const newCounterpartyId = await db.counterparties.add({
      name,
      logo: faker.image.avatar(),
      email,
      country,
      businessType,
      invoicePrefix: genInvoicePrefix(),
      spend: '0',
      income: '0',
      transactionsSum: '0',
      currency: Currency.Usd,
      lastTransactionDate: new Date(),
      permissions: { read: true, update: true, destroy: false },
      paymentMethods: [CounterpartyPaymentMethod.Ach],
    });

    const newCounterparty = await db.counterparties
      .where('id')
      .equals(newCounterpartyId)
      .first();

    await delay(500);
    return HttpResponse.json(newCounterparty);
  },
);

export const updateCounterparty = http.patch(
  `${baseUrl}/core/counterparty/:id/`,
  async ({ request, params }) => {
    const body = (await request.json()) as object;
    const { name, email, country, businessType } = camelizeKeys(body);
    const id = Number(params.id);

    await db.counterparties.update(id, {
      ...(name && { name }),
      ...(country && { country }),
      ...(businessType && { businessType }),
      email: email || '',
    });
    const updatedCounterparty = await db.counterparties
      .where('id')
      .equals(id)
      .first();

    await delay(500);
    return HttpResponse.json(updatedCounterparty);
  },
);

export const createCounterpartyPaymentAccount = http.post(
  `${baseUrl}/karta/counterparty_payment_account/`,
  async ({ request }) => {
    const body = (await request.json()) as object;
    const data = camelizeKeys(body as CounterpartyPaymentAccountCreateRequest);

    const newCounterpartyPaymentAccountId =
      await db.counterpartyPaymentAccounts.add({
        ...data,
        bank: {
          name: faker.company.name(),
          logo: faker.image.avatar(),
        },
        isDraft: false,
        permissions: {
          read: true,
          update: false,
          destroy: false,
        },
      });

    const newCounterpartyPaymentAccount = await db.counterpartyPaymentAccounts
      .where('id')
      .equals(newCounterpartyPaymentAccountId)
      .first();

    await delay(500);
    return HttpResponse.json(newCounterpartyPaymentAccount);
  },
);

export const readCounterpartyPaymentAccounts = http.get(
  `${baseUrl}/karta/counterparty_payment_account/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const counterpartyIds = convertStringToArray('counterparty_ids', url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const includedIds = convertStringToArray('included_ids', url);

    const filterByIncludedIds = (
      counterpartyPaymentAccount: CounterpartyPaymentAccount,
    ) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds
        .map(id => Number(id))
        .includes(Number(counterpartyPaymentAccount.id));
    };

    const filterByIncludedIdsExcept = (
      counterpartyPaymentAccount: CounterpartyPaymentAccount,
    ) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds
        .map(id => Number(id))
        .includes(Number(counterpartyPaymentAccount.id));
    };

    const filterByCounterpartyId = (
      counterpartyPaymentAccount: CounterpartyPaymentAccount,
    ) => {
      if (!counterpartyIds?.length) return true;

      return counterpartyIds.includes(
        Number(counterpartyPaymentAccount.counterpartyId),
      );
    };

    const count = await db.counterpartyPaymentAccounts
      .where('name')
      .startsWithIgnoreCase(search)
      .filter(filterByCounterpartyId)
      .count();

    const includedCounterpartyPaymentAccounts = includedIds.length
      ? await db.counterpartyPaymentAccounts
          .where('name')
          .startsWithIgnoreCase(search)
          .filter(filterByCounterpartyId)
          .filter(filterByIncludedIds)
          .toArray()
      : [];

    const otherCounterpartyPaymentAccounts =
      await db.counterpartyPaymentAccounts
        .where('name')
        .startsWithIgnoreCase(search)
        .filter(filterByCounterpartyId)
        .filter(filterByIncludedIdsExcept)
        .offset(offset)
        .limit(limit)
        .reverse()
        .sortBy('id');

    const results = [
      ...includedCounterpartyPaymentAccounts,
      ...otherCounterpartyPaymentAccounts,
    ];

    await delay(300);

    return HttpResponse.json({
      results,
      count,
    });
  },
);
